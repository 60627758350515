import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { Button, ButtonProps } from '@pancakeswap/uikit'
import { ConnectorNames, createWallets, getDocLink } from 'config/wallet'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useConnect } from 'wagmi'
import { ChainId } from '@pancakeswap/sdk'
import Trans from './Trans'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { login } = useAuth()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const { chainId } = useActiveChainId()
  const [open, setOpen] = useState(false)

  const docLink = useMemo(() => getDocLink(code), [code])

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      setOpen(true)
    }
  }

    const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])
    const filterWallets = useMemo(() => {
        if (chainId === ChainId.ONUS || chainId === ChainId.ONUS_TESTNET) {
            return wallets.filter(wallet => wallet.id !== ConnectorNames.Unipass && wallet.id !== ConnectorNames.Sequence)
        }
        return wallets;
    }, [chainId, wallets]);

  return (
    <>
      <CsButtonConnect onClick={handleClick} {...props}>
        {children || <Trans>Kết nối ví</Trans>}
      </CsButtonConnect>
      <WalletModalV2
        docText={t('Tìm hiểu cách kết nối')}
        docLink={docLink}
        isOpen={open}
        wallets={filterWallets}
        login={login}
        onDismiss={() => setOpen(false)}
      />
    </>
  )
}
const CsButtonConnect = styled(Button)`
  border: 1px solid ${({theme}) => theme.colors.primary};
`

export default ConnectWalletButton
