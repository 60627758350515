import { accountProps } from "state/auth/type";

export const dataUser: accountProps[] = [
    {
        account: '0x375809e55a0e0d447489dcce99a38d5b098bcbe5',
        type: 1,
        name: 'Quỹ đầu tư khởi nghiệp sáng tạo FUNDGO',
        image: 'images/logo-fundgo.png'
    },
    {
        account: '0x7efc1cda6ce660bd8501744cd42261bf8369d2f7',
        type: 2,
        name: 'JadeLabs Pte (Singapore)',
        image: 'images/ccqFundgo/jadlabs.png'
    },
]